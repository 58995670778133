import axios from 'axios'

class ApiService {
  constructor() {
    if(process.env.NODE_ENV === 'production') {
      this.baseUrl = `${window.location.origin}/`
    } else {
      this.baseUrl = 'http://localhost:3000/'
    }
  }

  service = () => {
    // service.interceptors.response.use(
    //   (response) => this.handleSuccess(response),
    //   (error) => this.handleError(error),
    // )

    return axios.create({
      headers: {
        token: localStorage.token,
        cartToken: localStorage.cart_token,
        'Access-Control-Allow-Origin': '*',
        csrf: 'token',
      },
    })
  }

  handleSuccess = (response) => {
    return response
  }

  handleError(error) {
    switch (error.response.status) {
      case 404:
        console.log('Error 404')
        this.redirectTo(document, '/404')
        break
      case 422:
        console.log('Error 422')
        break
      case 500:
        console.log('Error 500')
        this.redirectTo(document, '/500')
        break
      default:
      // do nothing
    }
    return Promise.reject(error)
  }

  redirectTo = (document, path) => {
    document.location = path
  }

  get(resource) {
    return this.service().get(this.baseUrl + resource)
  }

  post(resource, payload) {
    return this.service().request({
      method: 'POST',
      url: this.baseUrl + resource,
      responseType: 'json',
      data: payload,
    })
  }

  put(resource, payload) {
    return this.service().request({
      method: 'PUT',
      url: this.baseUrl + resource,
      responseType: 'json',
      data: payload,
    })
  }

  delete(resource, payload) {
    return this.service().request({
      method: 'DELETE',
      url: this.baseUrl + resource,
      responseType: 'json',
      data: payload,
    })
  }
}

export default new ApiService()
